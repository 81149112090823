import { Component } from 'react';

class About extends Component {

    render() {
        return (
            <div className="body">
                <h2 className="tab-header">Robinson Consulting</h2>
                <p>
                    Founded in 1998, RICHARD ROBINSON is the <b>Managing Director at Robinson Consulting 
                    (RPR Enterprises Inc.)</b>, a boutique recruitment consulting organization servicing North America.  
                    His focus is to continue to build and grow the brand and reputation in the Enterprise Software, Services 
                    and IT client portfolio.
                </p>
                <div id="rich-pic">
                    <img src={"/richpix.jpg"} alt="Richard Robinson"/>
                </div>
                <p>
                    Richard brings a unique blend of corporate, independent and agency staffing expertise to RPR Enterprises. 
                    With more that 18+ years' experience in the Enterprise Software, Services and Information Technology space 
                    with organization such as <b>SAP, Microsoft, Google, Salesforce.com, Workday, DocuSign, ProCore, KPMG, Eloqua, 
                    OpenText and HP.</b> He offers leadership and recruitment execution at its highest level to our client roster.
                </p>
                <p>
                    One of the cores to his recruitment success has to do with his acquired understanding of business process 
                    and methodologies coupled with his ability to apply them to recruitment best practices. The results have
                    been great value to the clients he's been engaged with and ultimately hiring the right candidate in a very 
                    timely manner. 
                </p>
                <a href="http://ca.linkedin.com/in/rpr10" target="_blank" rel="noopener noreferrer">
                    Public Profile - LinkedIn
                </a>
                <p className="before-footer">
                    <b>Specialties: Sales, Presales, Marketing, Professional Services and Executive Recruitment</b>
                </p>
            </div>

            );
    }

}

export default About;