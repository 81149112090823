import { Component } from 'react';

class Resources extends Component {
    
    render () {
        return (
            <div className="body">
                <h2 className="tab-header">
                    Candidate Resources
                </h2>
                <img className="header-photo" src={"1FDBC447-9E04-4DF7-AAFB-C238762815FF.jpeg"} alt="Interview prep"/>
                <p>
                    Here are a few ideas to assist in preparing for your interview.  
                </p>

                <p>
                    <b>Interview Preparation:</b>
                </p>
                <p>
                    While each interviewer has their own interview style and questions they ask, consider the following items in your preparation:
                </p>
                <ol>
                    <li>Why are you looking and what are you looking for?</li>
                    <li>What are the factors that will impact the decision you make?</li>
                    <li>What makes you unique?</li>
                    <li>Why do I think that's important?</li>
                    <li>What do I bring to the table to materially contribute to the mission, vision and field execution of our team?</li>

                </ol>
                
                <p>
                    <a href="https://www.youtube.com/watch?v=URs2vLAPNT0">
                        How to prepare for Competency or Behavioural based questions
                    </a>
                </p>
                <p>
                    <b>What are interviewers looking for in the interview process and how will the interviewer(s) be assessing you?</b>  
                </p>
                <p>
                    They will be thinking or asking questions to determine:
                </p>
                <p>
                    <b>Competency alignment:</b> 
                </p>
                <p>
                    Are you a champion of change, can you drive value creation, do you know how to leverage and lead internal resources, can you create and manage sales opportunities?
                </p>
                <p>
                    <b>Culturally alignment:</b>
                </p>
                <p>
                    Are you competitive? Do you have an internal fire to succeed?  Do you fit into our culture? Are you coachable and trainable? Do you have the aptitude and attitude to be successful in our company?
                </p>
                <p>
                    Take some time to review your interviewer's Linked In profile!  Sounds simple but consider this a first time customer meeting and treat the engagement same.
                </p>
                <p>
                    <b> Sales Interviews:</b>
                </p>
                <p>
                    Finally, think of 1 or 2 deals that you are particularly proud of and be prepared to answer the follow:
                </p>
                <ol>
                    <li>Who did you complete against?</li>
                    <li>Why did you win the deal?</li>
                    <li>How did you differentiate your value proposition from your competitor(s)?</li>
                    <li>What was the financial impact to the customer (saving, increased productivity, ROI realized etc.).</li>
                </ol>
                <p className="before-footer">
                    Please be prepared to ask the interviewer 2-3 "strategic" questions about their business.  No "softball" questions, hard hitters so they know you are a thinker!
                </p>

            </div>
        );
    }
}

export default Resources;