import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function CarouselComponent() {
    
    return (
        <div className="carousel-wrapper">
            <Carousel infiniteLoop autoPlay 
                showArrows={false} showIndicators={false} transitionTime="600" interval="3500"
                height="90%" dynamicHeight={false} showThumbs={false}
            >
                <div className="carousel-pics">
                    <img alt="1 in Carousel" src={"/teammate.jpeg"}/>
                </div>
                <div className="carousel-pics">
                    <img alt="2 in Carousel" src={"/39624E86-54DA-4B5A-B719-AB14128F3ED1.jpeg"}/>
                </div>
                <div className="carousel-pics">
                    <img alt="3 in Carousel" src={"/5A8D4092-58E0-4CB9-BB8E-AA9A2BA08D6D.jpeg"}/>
                </div>
                <div className="carousel-pics">
                    <img alt="4 in Carousel" src={"/9B5D6B57-9C04-47F4-9748-AB23930B9CB1.jpeg"}/>
                </div> 
                <div className="carousel-pics">
                    <img alt="5 in Carousel" src={"/B0BDEE64-9062-4B9F-A674-ED5D5450B5B4.jpeg"}/>
                </div>
                <div className="carousel-pics">
                    <img alt="6 in Carousel" src={"/swagger.jpg"}/>
                </div>
                
            </Carousel>
        </div>
    );
}