import { Component } from 'react';

class Success extends Component {

    render() {

        return (

            <div className="body">
                <h2 className="tab-header">Success Stories</h2>
                <img className="header-photo" alt="Success" src={"success.jpg"}  />
                <div>
                        <h5>Ryan Barretto</h5>
                        <h6>SVP Global Sales at Sprout Social, Inc.</h6>
                        <p>
                                I had the pleasure of working with Richard Robinson for a number of years. In a fast growing business, you work
                                and interact with many recruiters. However, Richard is one that simply impressed on every interaction. 
                                He is the consummate professional, driven, focused, and charismatic. His attention to detail, follow-up,
                                and understanding of our business was simply outstanding He is a class act and a person I would love to
                                work with again.
                        </p>
                </div>
                <div>
                        <h5>
                                Mark R. Steinke
                        </h5>
                        <h6>
                                Elite Business Coach for Business Owners & Coach to 3 of the 2019 Top 100 Philadelphia fastest growing companies.
                        </h6>
                        <p>
                                Rich Robinson was brought on to drive SAP recruiting in Canada for our Sales and Consulting Sales organization. Rich is a very Sr. Recruiter with excellent skills. Rich's ability to manage the hiring manager's expectations, drive the recruit through to completion is very good. Rich understands the business of Recruiting, possess excellent sourcing, screening and closing skills. I highly recommend Rich!
                        </p>       
                </div>
                <div>
                        <h5>
                                Doug Perry
                        </h5>
                        <h6>
                                Senior Area Vice President at Salesforce.com
                        </h6>
                        <p>
                                Richard and I worked together at Salesforce.com and we were very active in interviewing and hiring many "A players" into the organization through a period of rapid company growth. Richard quickly established a sense for the right "fit" for the organization and was thorough in his research and vetting of appropriate candidates. Hard-working, dedicated and a team-player, I felt he had strong judgement in the decision-making process and was a pleasure to work with.
                        </p>     
                </div>

                <div>
                        <h5>

                        Conrad Mandala
                        </h5>
                        <h6>
                        Vice President Global Alliances at Kinaxis
                        </h6>
                        <p>
                        Richard Robinson is by far the most professional recruiter I have worked with. His attention to detail and professionalism are surpassed only by his acute understanding of the business and how impactful HR decisions can be. He takes the time to grasp the requirement and then delivers against it. A true asset!
                        </p>    
                </div>
                <div>
                        <h5>
                                Jenn Cutajar
                        </h5>
                        <h6>
                        Coach | Sales Leader | Entrepreneur | President at Clear The Noise Coaching Inc.
                        </h6>
                        <p>
                        Richard is the epitome of passion and enthusiasm for his work. He played a large role in my decision to join SAP - I wanted to work for a company where even the recruiter was happy and loving his job!
                        </p>  
                </div>
                <div>
                        <h5>
                                Christopher Morrison
                        </h5>
                        <h6>
                                Director Global Procurement at Deloitte
                        </h6>
                        <p>
                                Richard is a highly productive recruitment professional. He delivered value by offering his expertise to the management team. He was effective at connecting relevant professionals to our business. A consummate professional and client-focused, I would rate him as one of the most quality-oriented recruiters in the industry. I've worked with him as a client and colleague. I highly recommend him!
                        </p>        
                </div>
                <div className="before-footer">
                        <h5>
                                Dave Miller
                        </h5>
                        <h6>
                                Digital Transformer | Dynamics 365 & AI
                        </h6>
                        <p>
                                Richard is a highly effective/experienced recruitment professional. His combination of business insight and sales savvy makes him an asset to any organization. He has worked on some of our toughest searches with the most demanding requirements and has delivered great results. He has a strong ability to build relationships, manage expectations and consistently deliver. Dave Miller VP Sales
                        </p>    
                </div>
            </div>
            );
    }
}

export default Success;