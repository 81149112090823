import { Component } from "react";

import CarouselComponent from "./CarouselComponent.js";

class Home extends Component {

	render() {
		return (
			<div id="home-body" className="body">
				<h1>Robinson Consulting</h1>
				<h2>
					<i>Exceptional Opportunities, Exceptional Talent.</i>
				</h2>
				<CarouselComponent />

				<h6 className="before-footer">
					As a result of our own Talent Acquisition experiences in Sales, Consulting,
					IT and Marketing, we have become one of Toronto's most professional and experienced
					boutique Recruitment agencies. Our priority is to guide and assist both clients and candidates 
					across a wide range of industries and markets, helping introduce talent to opportunity!
				</h6>
			</div>
		);
	}
}

export default Home;