import { Component } from 'react';

class Contact extends Component {

    render() {
        return (
            <div className="body">
                <h2> Contact Information </h2>
                <div>
                    <h5>Robinson Consulting</h5>
                    <p>
                        <a href="mailto:Richard@robinsonconsulting.ca">e: Richard@robinsonconsulting.ca</a>
                        <br/>
                        t: (416) 704-5084
                        <br/>
                        <a href="http://ca.linkedin.com/in/rpr10" target="_blank" rel="noopener noreferrer">LinkedIn - http://ca.linkedin.com/in/rpr10</a>
                    </p>
                    <p>    
                        <a href="mailto:Christine@robinsonconsulting.ca">e: Christine@robinsonconsulting.ca</a>
                        <br/>
                        t: (416) 723-0571
                        <br/>
                        <a href="https://www.linkedin.com/in/christine-robinson-1a304312/" target="_blank" rel="noopener noreferrer">LinkedIn - https://www.linkedin.com/in/christine-robinson-1a304312/</a>
                    </p>

                    <h6>Toronto Office:</h6>
                    <p>5466 Valhalla Cres. Suite 100<br/>
                        Mississauga, ON L5M 0K7<br/>
                        Canada
                    </p>
                    <h6>Ottawa Office:</h6>
                    <p>
                        33 Heney St. Suite 9<br/>
                        Ottawa, ON K1N 5V6<br/>
                        Canada
                    </p>
                    <h6>Vancouver Office:</h6>
                    <p>
                        1500 Ostler Ct. Suite 101<br/>
                        North Vancouver, BC<br/>
                        Canada
                    </p>
                    <h6>Lead Technical Consultant</h6>
                    <p>
                        Braedan Robinson
                        <br/>
                        <a href="mailto:Braedantye@msn.com">e: Braedan@robinsonconsulting.ca</a>
                        <br/>
                        t: 647-823-7561
                    </p>
                </div>
            </div>
        );
    }
}

export default Contact;