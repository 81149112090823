import './App.css';

import Home from './components/Home.js';
import About from './components/About.js';
import Contact from './components/Contact.js';
import Success from './components/Success.js';
import Resources from './components/Resources.js'


import { Component } from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
    
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      enableMenu: false,
      darkLogo: true,
      logoFocused: false
    };
  }

  componentDidMount() {
    if (window.screen.width > 578) {
      this.setState({enableMenu: true})
    }
  }

  openMenu = () => {
    this.state.enableMenu ? this.setState({enableMenu: false}): this.setState({enableMenu: true});
  }

  logoFocus = () => {
    this.state.darkLogo ? 
      this.setState({darkLogo: false, logoFocused: true}): this.setState({darkLogo: true, logoFocused: false});
  }

  logoMouseOver = () => {
      this.state.darkLogo && !this.state.logoFocused ? 
        this.setState({darkLogo: false}): this.setState({darkLogo: true});
  }

  render() {

    return (
      <Router className="wrapper">
        <Helmet>
          <meta charSet="utf-8"/>
          <title>Robinson Consulting</title>
          <link rel="icon" href="/RConsulting-favicon.png" />
        </Helmet>
        <div className="app-body">
          {
            window.screen.width < 578 &&
            <div className="row">
              <div onClick={() => this.openMenu()} className="col-sm-2">Menu</div>
            </div>
          }
          { this.state.enableMenu &&
          <div className="row">
              <Link id="logo" to="/" className="col-sm-2"
                onFocus={() => this.logoFocus()} 
                onBlur={() => this.logoFocus()}
                onMouseEnter={() => this.logoMouseOver()}
                onMouseLeave={() => this.logoMouseOver()}
                >
                { this.state.darkLogo &&
                  <img id="rc" alt="Robinson Consulting" src={"/RConsulting-4.png"} />
                }
                { !this.state.darkLogo && 
                  <img id="rc" alt="Robinson Consulting" src={"/RConsulting-4-white.png"} />

                }
              </Link >
              <Link to="/about" className="col-sm-2">
                  About
              </Link>
              <Link to="/resources" className="col-sm-2">
                  Resources
              </Link>
              <Link to="/success" className="col-sm-2">
                  Success
              </Link>
              <Link to="/contact" className="col-sm-2">
                  Contact
              </Link>
          </div>
          }

          <Switch>
            <Route path="/about">
              <About/>
            </Route>
            <Route path="/success">
              <Success/>
            </Route>
            <Route path="/resources">
              <Resources/>
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/">
              <Home/>
            </Route>

          </Switch>
          <div className="footer">
            <div className="designer">BTR Web Design </div>
            <div className="Copyright">2021 Robinson Consulting (a <i>RPR Enterprises Inc</i> Company)
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
